import { Injectable } from "@angular/core";
import { IdentityService } from "../../../core/identity.service";
import { ClassContent } from "../../../model/types/class-content";
import { ClassReportModelService } from "../../../model/reportcard/class-report-model.service";
import { MyEnglishModelService } from "../../../model/commerce/my-english-model.service";
import { AffiliationModelService } from "../../../model/identity/affiliation-model.service";
import { AccountAffiliation, AffiliationClass, AffiliationGroup } from "../../../model/types/account-affiliation";
import { ClassAnnouncement } from "../../../model/types/class-announcement";
import { ClassGoalSummary } from "../../../model/types/class-goal-summary";
import { ClassReport, REPORT_TYPE, REPORT_TYPES } from "../../../model/types/class-report";
import { Organization } from "../../../model/types/organization";
import { PROGRESS_ALL } from "../../../model/types/content";
import { Emitter } from "../../../core/emitters/emitter";
import { FeatureService } from "../../../core/feature.service";
import { ClassIdentityModelService } from "../../../model/identity/class-identity-model.service";
import { AccountModelService } from "../../../model/identity/account-model.service";
import { catchError, finalize, first, map as rxJsMap, mergeMap, share, tap } from "rxjs/operators";
import { forkJoin, Observable, of, Subscription, throwError } from "rxjs";
import { Logger } from "../../../core/logger/logger";
import { WordList } from "../../../model/types/word-list-reference";
import { VocabBuilderMode } from "../../../model/types/vocab-builder-reference";
import { VocabBuilderModelService } from "../../../model/content/vocab-builder-model.service";
import { VocabBuilderClassSetting } from "../../../model/types/vocab-builder-settings";
import { assign, clone, filter, find, get, has, head, isEmpty, isUndefined, reduce, map } from "lodash-es";
import { StorageCache } from "../../../core/storage-cache";
import { convertDateToBson, generateDefaultReportingPeriod, ReportingPeriod } from "../../../core/date-fns-util";
import { Partner } from "../../../model/types/partner";
import { addMilliseconds } from "date-fns";
import { MemoryCache } from "../../../core/memory-cache";
import { VltQuizScore } from "../../../model/reportcard/vocab-level-test";
import { ClassLevelTestSetting, ClassLevelTestSettings } from "../../../model/types/level-test-setting";
import { Dictionary } from "../../../model/types/dictionary";

export class MyClassState {
    classId: number;
    groupId: number;
    organizationId?: number;
}

export class ClassReportRange {
    fromDate: number;
    toDate: number;
}

export class AnnouncementPreviewMap {
    classes: { [key: string]: boolean } = {};
    groups: { [key: string]: boolean } = {};
}

@Injectable({providedIn: "root"})
export class MyClassAppStateService {
    static readonly EVENT_ON_PAGINATE = "onPaginate";
    static readonly EVENT_ON_CLASS_INITIALIZE = "onClassInitialize";
    static readonly EVENT_ON_GROUP_INITIALIZE = "onGroupInitialize";
    static readonly EVENT_ON_HYDRATE_MY_CLASS = "onHydrateMyClass";
    static readonly EVENT_ON_INITIAL_DATA_LOADING = "onInitialDataLoading";
    static readonly EVENT_ON_SHOW_COURSE_PROGRESS = "onShowCourseProgress";
    static readonly EVENT_ON_REPORTING_PERIOD_CHANGE = "onReportingPeriodChange";
    static readonly EVENT_ON_VOCAB_BUILDER_CLASS_SETTING_INITIALIZE = "onVocabBuilderClassSettingInitialize";
    static readonly PAGINATOR_MAX_SIZE = 5;
    static readonly MODAL_OPTIONS = {
        container: ".ec-pwa-v2-landing-app",
        centered: false
    };

    private emitter = new Emitter();
    private state = {
        classId: 0,
        groupId: 0
    };

    private logger = new Logger();
    // Cache
    private classStateStorageCache = new StorageCache<MyClassState>("ClassUrlStore");
    private classVocabSettingsCache = new MemoryCache<VocabBuilderClassSetting[]>();
    private announcementsCache = new MemoryCache<ClassAnnouncement[]>();

    private currentAffiliations?: AccountAffiliation;
    private currentOrganization: Organization;
    private currentClass?: ClassContent;
    private currentGroup?: ClassContent;
    private reportingPeriod?: ReportingPeriod;
    private vocabBuilderClassSetting?: VocabBuilderClassSetting[];
    private enrolled: boolean = false;
    private affiliated: boolean = false;
    private teacher: boolean = false;
    private accessCodeRedemptionMode: boolean = false;
    private skipRedeemAccessCode: boolean = false;

    // -------------------------------------
    private fetchCurrentAffiliationsObservable: Observable<AccountAffiliation | undefined>;
    private fetchCurrentOrganizationObservable: Observable<Organization | undefined>;
    private fetchCurrentClassObservable: Observable<ClassContent | undefined>;
    private fetchCurrentGroupObservable: Observable<ClassContent | undefined>;
    private fetchCoursesCountObservable: Observable<number>;
    private fetchDialogsCountObservable: Observable<number>;
    private fetchAnnouncementsObservable: Observable<ClassAnnouncement[]>;

    private announcementPreviewVisibilityMap: AnnouncementPreviewMap = {classes: {}, groups: {}};
    private wordList: WordList[] = [];
    private vocabBuilderModes: VocabBuilderMode[] = [];
    private coursesCount: number;
    private dialogsCount: number;

    // VLT Settings
    private vocabLevelTests: ClassLevelTestSetting[] = [];
    private vocabLevelTestSettings: Dictionary<ClassLevelTestSetting> = {};
    private levelTestScore: Map<number, VltQuizScore> = new Map();

    constructor(private identityService: IdentityService,
                private classModelService: ClassIdentityModelService,
                private myEnglishModelService: MyEnglishModelService,
                private classReportModelService: ClassReportModelService,
                private affiliationModelService: AffiliationModelService,
                private featureService: FeatureService,
                private accountModelService: AccountModelService,
                private vocabBuilderModelService: VocabBuilderModelService) {
    }

    getLastAccessedState(accountId: number): Observable<MyClassState> {
        if (!accountId) {
            return of({
                classId: undefined,
                groupId: undefined,
                organizationId: undefined
            });
        }

        return this.classStateStorageCache.getCache({accountId: accountId}, () => {
            return forkJoin([
                this.fetchAccountAffiliations(accountId),
                this.fetchDefaultClass(accountId)
            ]).pipe(
                rxJsMap(([accountAffiliations, defaultClass]: [AccountAffiliation?, ClassContent?]) => {
                    // Default class available
                    if (!isUndefined(defaultClass)) {
                        const classId = defaultClass.classID;
                        const firstGroupOfDefaultClass = this.getGroupsByClassId(accountAffiliations, classId);
                        const firstGroupId = get(firstGroupOfDefaultClass, "0.groupID", 0);
                        return {
                            classId: defaultClass.classID,
                            groupId: firstGroupId,
                            organizationId: defaultClass?.organizationID || 0
                        };
                    }
                    // Default class not available but
                    // Account affiliations are available, then re-direct to first class on the list
                    if (!isEmpty(accountAffiliations) && !isEmpty(accountAffiliations.classes)) {
                        const firstClass = head(accountAffiliations.classes);
                        const firstGroup = this.getGroupsByClassId(accountAffiliations, firstClass?.classID);
                        const firstGroupId = get(firstGroup, "groupID", 0);
                        return {
                            classId: firstClass?.classID,
                            groupId: firstGroupId,
                            organizationId: firstClass?.organizationID
                        };
                    }
                    // Default class and affiliations are both unavailable
                    return {classId: 0, groupId: 0, organizationId: undefined};
                })
            );
        });
    }

    getGroupsByClassId(accountAffiliation: AccountAffiliation, classId: number): AffiliationGroup[] {
        if (isEmpty(accountAffiliation) || isUndefined(classId)) {
            return [];
        }
        const groups = get(accountAffiliation, "groups", []);
        return filter(groups, (group: AffiliationGroup) => group.classID == classId);
    }

    static getGroupByClassId(accountAffiliations: AccountAffiliation, classId: number): AffiliationGroup | undefined {
        if (!accountAffiliations || !classId) {
            return undefined;
        }
        let got = get(accountAffiliations, "groups");
        return find(got, (group) => group.classID == classId);
    }

    getCurrentAffiliations(): AccountAffiliation {
        return this.currentAffiliations;
    }

    getCurrentOrganization(): Organization {
        return this.currentOrganization;
    }

    getCurrentClass(): ClassContent | undefined {
        return this.currentClass;
    }

    getCurrentGroup(): ClassContent {
        return this.currentGroup;
    }

    getState(): MyClassState {
        return this.state;
    }

    getAffiliationClassByClassId(classes: AffiliationClass[], classId: number): AffiliationClass {
        return find(classes, classContent => classContent.classID == classId);
    }

    getReportingPeriod(): ReportingPeriod | undefined {
        return this.reportingPeriod ? clone(this.reportingPeriod) : undefined;
    }

    getAnnouncementPreviewVisibility(type: string, id: number): boolean {
        if (!id) {
            return false;
        }
        return this.announcementPreviewVisibilityMap[type][id];
    }

    getWordList(): WordList[] {
        return this.wordList;
    }

    getVocabBuilderModes(): VocabBuilderMode[] {
        return this.vocabBuilderModes;
    }

    getVocabBuilderClassSetting(): VocabBuilderClassSetting[] {
        return this.vocabBuilderClassSetting;
    }

    getCoursesCount(): number {
        return this.coursesCount;
    }

    getDialogsCount(): number {
        return this.dialogsCount;
    }

    setAccessCodeRedemptionMode(isEnabled: boolean = false): void {
        this.accessCodeRedemptionMode = isEnabled;
    }

    setSkipRedeemAccessCode(skip: boolean = false): void {
        this.skipRedeemAccessCode = skip;
    }

    setCurrentAffiliations(affiliations: AccountAffiliation): void {
        this.currentAffiliations = affiliations;
        this.logger.log("Current affiliations: ", affiliations);
    }

    setCurrentOrganization(organization): void {
        this.currentOrganization = organization;
        this.logger.log("Current organization: ", organization);
    }

    setCurrentClass(currentClass: ClassContent): void {
        this.currentClass = currentClass;
        this.logger.log("Current class: ", currentClass);
    }

    setCurrentGroup(currentGroup: ClassContent): void {
        this.currentGroup = currentGroup;
        this.logger.log("Current group: ", currentGroup);
    }

    setAnnouncementPreviewVisibility(type: string, id: number, value: boolean): void {
        if (isUndefined(id) || isEmpty(type)) {
            return;
        }
        this.announcementPreviewVisibilityMap[type][id] = value;
    }

    setEnrolled(enrolled: boolean): void {
        this.enrolled = enrolled;
    }

    setAffiliated(affiliated: boolean): void {
        this.affiliated = affiliated;
    }

    setTeacher(isTeacher: boolean): void {
        this.teacher = isTeacher;
    }

    setState(params: MyClassState, shouldSetClassToCache: boolean = true): void {
        this.state = params;
        if (shouldSetClassToCache && this.isRequiredClassParamProvided(params)) {
            const accountId = this.identityService.getAccountId();
            // @FIXME: Do not use subscription inside services.
            this.getLastAccessedState(accountId).pipe(first()).subscribe((oldState) => {
                this.classStateStorageCache.setValue({accountId: accountId}, assign({}, oldState, params));
            });
        }
    }

    setReportingPeriod(reportingPeriod: ReportingPeriod, shouldPublish: boolean = false) {
        this.reportingPeriod = reportingPeriod;
        if (shouldPublish) {
            this.publish(MyClassAppStateService.EVENT_ON_REPORTING_PERIOD_CHANGE, this.reportingPeriod);
        }
    }

    setInitialReportingPeriod(shouldPublishReportingPeriod = false): void {
        const affiliationData = !isEmpty(this.currentGroup) ? this.currentGroup : this.currentClass;
        if (!affiliationData) {
            return;
        }
        const reportingPeriod = generateDefaultReportingPeriod(affiliationData);
        this.setReportingPeriod(reportingPeriod, shouldPublishReportingPeriod);
    }

    setInitialDataLoading(isInitialDataLoading: boolean): void {
        this.publish(MyClassAppStateService.EVENT_ON_INITIAL_DATA_LOADING, isInitialDataLoading);
    }

    setWordList(wordList: WordList[]): void {
        this.wordList = wordList;
    }

    setVocabBuilderModes(vocabBuilderModes: VocabBuilderMode[]): void {
        this.vocabBuilderModes = vocabBuilderModes;
    }

    isEnrolled(): boolean {
        return this.enrolled;
    }

    isTeacher(): boolean {
        return this.teacher;
    }

    isAffiliated(): boolean {
        return this.affiliated;
    }

    isAccessCodeRedemptionMode(): boolean {
        return this.accessCodeRedemptionMode;
    }

    isSkipRedeemAccessCode(): boolean {
        return this.skipRedeemAccessCode;
    }

    hasGroup(): boolean {
        return !isEmpty(this.currentGroup);
    }

    isPartnerClassiClass(): boolean {
        return this.getCurrentClass()?.partnerID == Partner.PARTNER_ID_CLASSI;
    }

    isSentencesReportEnabled(): boolean {
        return this.featureService.getFeature("isSentencesInSpokenLinesReportEnabled");
    }

    isRolePlaysTtReportEnabled(): boolean {
        return this.featureService.getFeature("isRolePlaysTtReportEnabled", false);
    }

    initializeAnnouncementPreviewVisibility(affiliation: ClassContent, type: string): void {
        if (isEmpty(affiliation)) {
            return;
        }
        const targetId = get(affiliation, type === "groups" ? "groupID" : "classID");
        const announcementVisibility = this.getAnnouncementPreviewVisibility(type, targetId);
        if (isUndefined(announcementVisibility)) {
            this.setAnnouncementPreviewVisibility(type, targetId, true);
        }
    }

    fetchInitialData(): Observable<VocabBuilderClassSetting[]> {
        return forkJoin([
            this.fetchAccountAffiliations(),
            this.fetchCurrentClass(),
            this.fetchCurrentGroup()
        ]).pipe(
            tap(() => {
                const classId = get(this.getState(), "classId");
                this.checkAffiliationStatus(classId);
                this.setInitialReportingPeriod();
            }),
            mergeMap((response) => {
                const organizationId = get(response[1], "organizationID");
                return this.fetchCurrentOrganizationData(organizationId);
            }),
            mergeMap(() => {
                // IF Class is a real class => fetch vocabBuilderSetting. vocabBuilderSetting is fetched at the beginning for
                // routing purposes. If class/group goals are enabled, we do not need to fetch vocabBuilderSetting because default
                // route should always be Videos in this case.
                const currentClass = this.getCurrentClass();
                if (isEmpty(currentClass)) {
                    return of(undefined);
                }
                return this.fetchVocabBuilderSetting();
            })
        );
    }

    private fetchVocabBuilderSetting(shouldPublishEvent: boolean = true): Observable<VocabBuilderClassSetting[] | undefined> {
        if (this.identityService.isAnonymous()) {
            return of(undefined);
        }
        let vocabBuilderSetting = this.getVocabBuilderClassSetting();
        if (vocabBuilderSetting) {
            if (shouldPublishEvent) {
                this.publish(MyClassAppStateService.EVENT_ON_VOCAB_BUILDER_CLASS_SETTING_INITIALIZE, vocabBuilderSetting);
            }
            return of(vocabBuilderSetting);
        }
        const classId = this.getState().classId;
        if (!classId) {
            return of(undefined);
        }

        return this.classVocabSettingsCache.getCache({classId: this.getState().classId, groupId: this.getState().groupId}, () => {
            return this.vocabBuilderModelService
                .getVocabBuilderSettingByClassIdV2(classId)
                .pipe(
                    catchError(() => of(undefined))
                );
        }).pipe(
            tap((vocabBuilderClassSetting: VocabBuilderClassSetting[]) => {
                this.vocabBuilderClassSetting = vocabBuilderClassSetting;
            })
        );
    }

    fetchAccountAffiliations(accountId?: number, shouldForceUpdate: boolean = false): Observable<AccountAffiliation | undefined> {
        if (this.identityService.isAnonymous() && !accountId) {
            return of(undefined);
        }
        const currentAffiliations = this.getCurrentAffiliations();
        if (currentAffiliations && !shouldForceUpdate) {
            return of(currentAffiliations);
        }

        if (this.fetchCurrentAffiliationsObservable) {
            return this.fetchCurrentAffiliationsObservable;
        }

        this.fetchCurrentAffiliationsObservable = this.affiliationModelService
            .getAccountAffiliation(this.identityService.getAccountId() || accountId)
            .pipe(
                catchError(() => of(undefined)),
                tap((affiliations) => {
                    this.setCurrentAffiliations(affiliations);
                    this.fetchCurrentAffiliationsObservable = undefined;
                }),
                share()
            );
        return this.fetchCurrentAffiliationsObservable;
    }

    private fetchCurrentOrganizationData(organizationId: number): Observable<Organization | undefined> {
        if (!organizationId) {
            return of(undefined);
        }
        let currentOrganization = this.getCurrentOrganization();
        if (currentOrganization) {
            return of(currentOrganization);
        }

        if (this.fetchCurrentOrganizationObservable) {
            return this.fetchCurrentOrganizationObservable;
        }

        this.fetchCurrentOrganizationObservable = this.classModelService.getOrganizationById(organizationId)
            .pipe(
                catchError(() => of(undefined)),
                tap((organization) => {
                    this.setCurrentOrganization(organization);
                    this.fetchCurrentOrganizationObservable = undefined;
                }),
                share()
            );
        return this.fetchCurrentOrganizationObservable;
    }

    private fetchCurrentClass(shouldPublishEvent: boolean = true): Observable<ClassContent | undefined> {
        let currentClass = this.getCurrentClass();
        if (currentClass) {
            if (shouldPublishEvent) {
                this.publish(MyClassAppStateService.EVENT_ON_CLASS_INITIALIZE, currentClass);
            }
            return of(currentClass);
        }

        let classId = this.getState().classId;
        if (!classId) {
            return of(undefined);
        }

        if (this.fetchCurrentClassObservable) {
            return this.fetchCurrentClassObservable;
        }

        this.fetchCurrentClassObservable = this.classModelService
            .getClassById(classId)
            .pipe(
                catchError(() => of(undefined)),
                mergeMap((classContent) => {
                    return this.fetchPartnerNames(classContent);
                }),
                tap((classContent) => {
                    this.setCurrentClass(classContent);
                    this.initializeAnnouncementPreviewVisibility(classContent, "classes");
                    if (shouldPublishEvent) {
                        this.publish(MyClassAppStateService.EVENT_ON_CLASS_INITIALIZE, classContent);
                    }
                    this.fetchCurrentClassObservable = undefined;
                }),
                share()
            );
        return this.fetchCurrentClassObservable;
    }

    private fetchCurrentGroup(shouldPublishEvent: boolean = true): Observable<ClassContent | undefined> {
        let currentGroup = this.getCurrentGroup();
        if (currentGroup) {
            if (shouldPublishEvent) {
                this.publish(MyClassAppStateService.EVENT_ON_GROUP_INITIALIZE, currentGroup);
            }
            return of(currentGroup);
        }
        let groupId = this.getState().groupId;
        if (!groupId) {
            return of(undefined);
        }

        if (this.fetchCurrentGroupObservable) {
            return this.fetchCurrentGroupObservable;
        }

        this.fetchCurrentGroupObservable = this.classModelService
            .getGroupById(groupId)
            .pipe(
                catchError(() => of(undefined)),
                tap((groupContent) => {
                    this.setCurrentGroup(groupContent);
                    this.initializeAnnouncementPreviewVisibility(groupContent, "groups");
                    if (shouldPublishEvent) {
                        this.publish(MyClassAppStateService.EVENT_ON_GROUP_INITIALIZE, groupContent);
                    }
                    this.fetchCurrentGroupObservable = undefined;
                }),
                share()
            );
        return this.fetchCurrentGroupObservable;
    }

    private fetchPartnerNames(classContent: ClassContent): Observable<ClassContent | undefined> {
        if (!classContent || isEmpty(classContent.teacherAccountID)) {
            return of(classContent);
        }
        return this.accountModelService
            .getPartnerUsernames(classContent.teacherAccountID.toString())
            .pipe(
                catchError(() => []),
                rxJsMap((partners) => {
                    if (isEmpty(partners)) {
                        return classContent;
                    }
                    let partner = head(partners);
                    if (has(partner, "Name")) {
                        classContent.teacherName = get(partner, "Name");
                    }
                    return classContent;
                })
            );
    }

    fetchDefaultClass(accountId?: number): Observable<ClassContent> {
        const id = this.identityService.getAccountId() || accountId;
        if (!id) {
            return of(undefined);
        }
        return this.classModelService
            .getDefaultClass(id)
            .pipe(catchError(() => of(undefined)));
    }

    fetchDialogsCount(): Observable<number> {
        if (this.identityService.isAnonymous()) {
            return of(0);
        }
        const params = {
            progressFilter: PROGRESS_ALL,
            ...this.getState()
        };
        this.fetchDialogsCountObservable = !params.classId ? of(0) : this.myEnglishModelService
            .getDialogCount(params)
            .pipe(
                catchError(() => of(0)),
                tap((count) => {
                    this.dialogsCount = count;
                }),
                finalize(() => this.fetchDialogsCountObservable = undefined)
            );
        return this.fetchDialogsCountObservable;
    }

    fetchCoursesCount(): Observable<number> {
        if (this.identityService.isAnonymous()) {
            return of(0);
        }
        const params = {
            progressFilter: PROGRESS_ALL,
            ...this.getState()
        };
        this.fetchCoursesCountObservable = !params.classId ? of(0) : this.myEnglishModelService
            .getCourseCount(params)
            .pipe(
                catchError(() => of(0)),
                tap((count) => {
                    this.coursesCount = count;
                }),
                finalize(() => this.fetchCoursesCountObservable = undefined)
            );
        return this.fetchCoursesCountObservable;
    }

    fetchGoalTotals(): Observable<ClassGoalSummary> {
        let reportingPeriod = this.getReportingPeriod();
        if (!reportingPeriod) {
            return throwError("Reporting period required");
        }
        const emptyGoalSummary = {} as ClassGoalSummary;
        let params = {
            accountId: this.identityService.getAccountId(),
            startDate: convertDateToBson(addMilliseconds(reportingPeriod.startDate, 1)),
            endDate: convertDateToBson(reportingPeriod.endDate),
            groupID: this.getState().groupId ? this.getState().groupId : undefined,
            skipCache: true
        };
        return this.classReportModelService.getRawGoalSummary(
            this.getState().classId,
            params
        ).pipe(catchError(() => of(emptyGoalSummary)));
    }

    fetchGoalReport(additionalParams: object = {}): Observable<ClassReport[]> {
        if (!this.isEnrolled()) {
            return of([]);
        }
        let reportingPeriod = this.getReportingPeriod();
        if (!reportingPeriod) {
            return throwError("Reporting period required");
        }
        if (this.identityService.isAnonymous()) {
            return of([]);
        }
        const reportTypes = this.isSentencesReportEnabled() ? [ ...REPORT_TYPES, REPORT_TYPE.SENTENCE ] : REPORT_TYPES;
        let params = assign({}, {
            accountID: this.identityService.getAccountId(),
            startDate: convertDateToBson(addMilliseconds(reportingPeriod.startDate, 1)),
            endDate: convertDateToBson(reportingPeriod.endDate),
            reportTypes: reportTypes.join(","),
            groupID: this.getState().groupId || undefined
        }, additionalParams);
        return this.classReportModelService
            .getReport(this.getState().classId, params)
            .pipe(catchError(() => of([])));
    }

    fetchPinnedAnnouncements(): Observable<ClassAnnouncement[]> {
        return this.fetchAnnouncements(true);
    }

    fetchAnnouncements(shouldFetchPinnedAnnouncements): Observable<ClassAnnouncement[]> {
        if (!isUndefined(this.fetchAnnouncementsObservable)) {
            return this.fetchAnnouncementsObservable;
        }
        const params = this.prepareAnnouncementParams(shouldFetchPinnedAnnouncements);
        const cacheKey = {...params, classId: this.getState().classId};
        return this.announcementsCache.getCache(cacheKey, () => {
            return this.classModelService.getAnnouncements(
                this.getState().classId,
                params
            ).pipe(
                catchError(() => of([])),
                finalize(() => this.fetchAnnouncementsObservable = undefined)
            );
        });
    }

    prepareAnnouncementParams(shouldPrepareForPinned: boolean = false, additionalParams = {}): { [key: string]: any } {
        const groupId = this.getState().groupId;
        const params = assign({}, {
            groupID: groupId || undefined,
            // Fetch only last 20 announcements
            page: 0,
            pageSize: 20,
            // Sort by dateCreated desc
            sortFieldsAndOrders: 3
        }, additionalParams);
        if (shouldPrepareForPinned) {
            params["pinned"] = true;
        }
        return params;
    }

    checkAffiliationStatus(classId: number): void {
        const affiliationErrorFn = (): void => {
            this.setEnrolled(false);
            this.setAffiliated(false);
            this.setTeacher(false);
        };
        if (!this.currentAffiliations || isEmpty(this.currentAffiliations.classes)) {
            return affiliationErrorFn();
        }
        const selectedClass = this.getAffiliationClassByClassId(this.currentAffiliations.classes, classId);
        if (isEmpty(selectedClass)) {
            return affiliationErrorFn();
        }
        this.setAffiliated(true);
        this.setEnrolled(selectedClass.isStudent || false);
        this.setTeacher(selectedClass.isTeacher || false);
    }

    resetCurrentAffiliations(): void {
        this.currentAffiliations = undefined;
        this.fetchCurrentAffiliationsObservable = undefined;
        this.affiliationModelService.clearAffiliationCache();
    }

    resetCurrentOrganization(): void {
        this.currentOrganization = undefined;
        this.fetchCurrentOrganizationObservable = undefined;
    }

    resetCurrentClass(): void {
        this.currentClass = undefined;
        this.fetchCurrentClassObservable = undefined;
    }

    resetCurrentGroup(): void {
        this.currentGroup = undefined;
        this.fetchCurrentGroupObservable = undefined;
    }

    resetVocabBuilderClassSetting(): void {
        this.vocabBuilderClassSetting = undefined;
    }

    resetFetchedData(shouldResetAffiliations: boolean = true): void {
        this.logger.log("Resetting myclass data...");
        if (shouldResetAffiliations) {
            this.resetCurrentAffiliations();
        }
        this.resetCurrentOrganization();
        this.resetCurrentClass();
        this.resetCurrentGroup();
        this.resetVocabBuilderClassSetting();
    }

    isRequiredClassParamProvided(params: MyClassState): boolean {
        return (params?.classId > 0 || params?.groupId > 0);
    }

    hasVocabLevelTestSettings(): boolean {
        return !isEmpty(this.vocabLevelTestSettings);
    }

    getVocabLevelTests(): ClassLevelTestSetting[] {
        return this.vocabLevelTests;
    }

    isLevelTestCompleted(classLevelTestId: number): boolean {
        return !isUndefined(this.levelTestScore.get(classLevelTestId));
    }

    fetchVocabLevelTestSettings(classId: number): Observable<VltQuizScore[]> {
        return this.vocabBuilderModelService
            .getLevelTestSetting(classId)
            .pipe(
                catchError(() => of({})),
                tap((settings: ClassLevelTestSettings) => {
                    if (!settings) {
                        return;
                    }
                    this.vocabLevelTests = settings.classLevelTestSettings;
                    this.vocabLevelTestSettings = reduce(this.vocabLevelTests, (acc, classLevelTestSetting: ClassLevelTestSetting) => {
                        return {...acc, [classLevelTestSetting.classLevelTestId]: classLevelTestSetting};
                    }, {}) as Dictionary<ClassLevelTestSetting>;
                }),
                mergeMap((settings: ClassLevelTestSettings) => {
                    if (!settings) {
                        return of([]);
                    }
                    const observablesBatch = map(settings.classLevelTestSettings, (classLevelTestSetting: ClassLevelTestSetting) => {
                        const levelTestSettingId = classLevelTestSetting.levelTestSetting ? classLevelTestSetting.levelTestSetting.levelTestSettingId : 0;

                        return this.vocabBuilderModelService.getCachedLevelTestScore( this.identityService.getAccountId(), levelTestSettingId, classLevelTestSetting.curatedLevelTestId)
                            .pipe(
                                catchError(() => of(undefined)),
                                tap((score: VltQuizScore) => {
                                    if (score) {
                                        this.levelTestScore.set(classLevelTestSetting.classLevelTestId, score);
                                    }
                                })
                            );
                    });
                    return forkJoin(observablesBatch);
                }
                ));
    }

    subscribe(eventName: string, successFn: (data?) => void, errorFn?: (e?) => void): Subscription {
        return this.emitter.subscribe(eventName, successFn, errorFn);
    }

    publish(eventName: string, data?: any): void {
        this.emitter.publish(eventName, data);
    }

    getObservable(eventName: string): Observable<any> {
        return this.emitter.getObservable(eventName);
    }

    destroy(): void {
        this.classReportModelService.deleteGoalSummaryCache();
        this.classReportModelService.deleteReportCache();
        this.announcementsCache.destroy();
        this.classVocabSettingsCache.destroy();
    }

}
